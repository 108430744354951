<template>
  <div class="page">
    <Viewheader :isHome="false" ref="navheader" />
    <!-- banner图 -->
    <BannerHead class="banner-wrap" :title="bigTitle_1" :desc="smallTitle_1">
      <div class="header-custom" slot="content">
        <div class="header-bg">
          <img class="banner1" src="@/assets/img/imgWhiteBg/banner-1.png" />
          <img class="banner2" src="@/assets/img/imgWhiteBg/banner-2.png" />
          <img class="banner3" src="@/assets/img/imgWhiteBg/banner-3.png" />
          <img class="banner4" src="@/assets/img/imgWhiteBg/banner-4.png" />
        </div>
        <div
          class="header-bg_left"
          :style="{ width: rangePercent + '%' }"
        ></div>
        <div
          class="header-bg_right"
          :style="{ width: 100 - rangePercent + '%' }"
        ></div>
        <img
          class="banner5"
          :style="{ left: rangePercent + '%' }"
          src="@/assets/img/imgWhiteBg/banner-arrow.png"
        />
        <input
          type="range"
          class="banner-range"
          min="1"
          max="100"
          value="50"
          v-model="rangePercent"
        />
      </div>
    </BannerHead>

    <!-- 核心功能 -->
    <div class="core-function">
      <CommonTitle title="核心功能" />
      <ColumuTwo class="core-function_main" :list="spanList" :img="coreImg">
        <div slot:img class="common-two-column_img">
          <img class="core1" src="@/assets/img/imgWhiteBg/core-1.png" />
          <div class="core-list">
            <img class="core2" src="@/assets/img/imgWhiteBg/core-2.png" />
            <img class="core3" src="@/assets/img/imgWhiteBg/core-3.png" />
            <img class="core4" src="@/assets/img/imgWhiteBg/core-4.png" />
          </div>
        </div>
      </ColumuTwo>
    </div>
    <!-- 能力展示 -->
    <div class="similar-main-wrap">
      <div class="similar-main">
        <CommonTitle title="能力展示" :desc="ability.desc" />
        <div class="operationWidth similar-main-cont">
          <div class="operationBackgroundColor">
            <ImgUpload
              class="sc-img-upload"
              @success="getResultImg"
              @select="selectPicture"
              @change="handleChange"
              :modelIndex.sync="imgCurrentActive"
              :imgList="exampleDiagram"
              :action="action"
              :directionRow1280="true"
              accept="image/jpeg,image/jpg,image/png"
              uploadText="选择一张"
            />
            <div class="operationRight">
              <div class="optimizationButton" v-if="afterOptimization">
                优化后
              </div>
              <div class="downloadButton" @click="imgDowns" v-if="down">
                下载
              </div>
              <!-- 成功图片 -->

              <div v-if="successImg" class="successImg">
                <!-- <img :src="this.resultGraphImg"> -->
                <!-- ,backgroundSize: `${this.imgWidth}  ${this.imgHeight}` -->
                <div
                  class="sliderImg background-img"
                  :style="{ backgroundImage: `url(${this.img_url})` }"
                ></div>
                <div
                  class="sliderImg foreground-img"
                  :style="{ backgroundImage: `url(${this.resultGraphImg}) ` }"
                ></div>
                <input
                  type="range"
                  min="1"
                  max="100"
                  value="50"
                  class="slider-viewer"
                  id="slider"
                />
                <div class="trigger-gesture">
                  <img src="@/assets/img/imgWaterMark/ability-1.png" alt="" />
                </div>
                <div class="afterOptimization">优化前</div>
              </div>
              <!-- 报错图片 -->
              <div class="errMain" v-if="errImg">
                <div class="errImg">
                  <img src="../../assets/img/err.png" />
                </div>
                <div class="errText">
                  {{ errText }}
                </div>
              </div>

              <!-- 加载特效 -->
              <div class="loadSpecialEffects" v-if="loadImg"></div>
              <!-- 扫描脚框 -->
              <div class="top" v-if="loadImg"></div>
              <div class="bottom" v-if="loadImg"></div>
            </div>
          </div>
          <div class="actionText">
            <div class="detectionText">
              <input
                type="text"
                placeholder="粘贴网络图片URL"
                v-model="urlAdress"
              />
              <button
                class="detectionButton"
                @click="whiteBackgroundMap(1)"
                :disabled="this.urlAdress == ''"
              >
                检测
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 差异优势 -->
    <div class="different-advantage-wrap">
      <div class="different-advantage">
        <CommonTitle title="差异优势" theme="light" />
        <ColumuTwo
          class="different-advantage_main different-advantage_main_top"
          theme="light"
          :list="diffList[0]"
        >
          <div class="diff-top" slot="img">
            <img :src="diffImg" />
          </div>
        </ColumuTwo>
        <ColumuTwo
          class="different-advantage_main different-advantage_main_bottom"
          :list="diffList[1]"
          :reverse="true"
          theme="light"
        >
          <div slot="img" class="diff-imgs">
            <img class="diff-bar" :src="diffImg2" />
            <div class="diff-list">
              <img class="diff-bar" src="@/assets/img/imgWhiteBg/diff-3.png" />
              <img class="diff-bar" src="@/assets/img/imgWhiteBg/diff-4.png" />
              <img class="diff-bar" src="@/assets/img/imgWhiteBg/diff-5.png" />
              <img class="diff-bar" src="@/assets/img/imgWhiteBg/diff-6.png" />
            </div>
            <img
              class="diff-person"
              src="@/assets/img/imgWhiteBg/diff-1.png"
              alt=""
            />
          </div>
        </ColumuTwo>
      </div>
    </div>

    <!-- 应用场景 -->
    <div class="application-scene">
      <CommonTitle title="应用场景" />
      <ColumuTwo class="application-scene_main">
        <div slot="text">
          <div class="app-title">
            {{ appliList.title }}
          </div>
          <ul class="app-list">
            <li v-for="(item, index) in appliList.desc" :key="index">
              <img src="../../assets/img/imgSimilar/scene-3.png" alt="" />
              {{ item }}
            </li>
          </ul>
        </div>
        <div slot:img class="app-imgs">
          <img src="@/assets/img/imgWhiteBg/app-1.png" class="app1" />
          <img src="@/assets/img/imgWhiteBg/app-2.png" class="app2" />
          <img src="@/assets/img/imgWhiteBg/app-3.png" class="app3" />
        </div>
      </ColumuTwo>
    </div>

    <!-- 尾部 -->
    <Footering />
  </div>
</template>

<script>
// banner
import BannerHead from './components/Banner.vue';
import CommonTitle from './components/CommonTitle.vue';
import ColumuTwo from './components/ColumuTwo.vue';
// 上传图片列表组件
import ImgUpload from '@/components/secondPage/imgUpload.vue';
import googleLogo from '@/assets/img/imgSimilar/google.png';

import { whiteBackground } from '@/api/aidata.js';
import { apiUri } from '../../api/txt';

// 公用组件
import '../../assets/css/public.css';
import '../../assets/css/imgPublic.css';
import '../../assets/css/imgPublic.css';
import '../../assets/css/commonModule.css';
// 头部
import Viewheader from '../../components/viewheader/viewheader.vue';
// foote组件
import Footering from '@/components/foot/footer.vue';
export default {
  components: {
    BannerHead,
    CommonTitle,
    ColumuTwo,
    ImgUpload,
    Viewheader,
    Footering,
  },
  watch: {
    rangePercent(val) {
      if (val == 1) {
        this.rangePercent = 0;
      }
      const title = document.querySelector('.banner-title');
      const text = document.querySelector('.banner-text');
      this.textGradient = `linear-gradient(90deg, #6c38e0 0%,#6c38e0 ${this.rangePercent}%, #fff ${this.rangePercent}%, #fff 100% )`;
      title.style.background = this.textGradient;
      text.style.background = this.textGradient;
    },
  },
  data() {
    return {
      textGradient:
        'linear-gradient(90deg, #6c38e0 0%,#6c38e0 50%, #fff 50%, #fff 100% )',
      rangePercent: 50,
      coreImg: require('@/assets/img/imgWhiteBg/core-1.png'),
      bigTitle_1: '商品图片抠白底',
      smallTitle_1: '批量将商品图片处理为白底图/透明通道图',

      // 核心功能
      spanList: [
        {
          title: '商品图片白底处理',
          desc: '电商各品类商品图白底化处理，输出白底图及透明通道图。',
        },
        {
          title: `接口<span style='color:#6C38E0'>批量处理</span>&<span style='color:#6C38E0'>结果评估</span>`,
          desc: '支持商品图批量处理，并对处理结果的效果进行模型打分。',
        },
      ],

      //   差异优势

      diffImg: require('@/assets/img/imgWhiteBg/diff-2.gif'),
      diffImg2: require('@/assets/img/imgWhiteBg/diff-7.png'),
      diffList: [
        [
          {
            title: '跨境场景数据训练',
            desc: '针对跨境电商数据进行训练及优化，特别在鞋包服装等类目效果更优。',
          },
        ],
        [
          {
            title: '支持处理复杂图片背景',
            desc: '不仅支持纯色、主体背景对比度高的图片，还支持较复杂背景图的抠图。',
          },
        ],
      ],

      //   应用场景
      appliList: {
        title: '店铺运营提效',
        desc: [
          '各电商平台，白底商品图提升用户点击。',
          '批量处理商品图，以使其符合Facebook、Marketplace、Target、Amazon、googleshopping等平台投放准入要求。',
          '站内外投放海报，作为海报中商品主体图元素。',
          '大促会场入口海报个性化。',
        ],
      },

      // 能力展示
      ability: {
        desc: `模型结果对<img src='${googleLogo}' /> <span style='color:#6C38E0'>google 系列产品（shopping/⾕歌seo等）</span>，进⾏了针对性优化，在此场景下使⽤效果更优。模型⽬前仅⽀持png、jpg、jpeg。`,
      },

      // header请求
      action: `${apiUri}/img/whiteBase`,
      img_url: '',
      result_url: '',
      headers: {
        token: localStorage.getItem('tokenKey')
          ? localStorage.getItem('tokenKey')
          : '',
        isSample: 1,
      },
      // 示例图列表
      exampleDiagram: [
        {
          img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/img/koutu_001.jpg',
        },
        {
          img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/img/koutu_002.jpg',
        },
        {
          img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/img/koutu_003.jpg',
        },
        {
          img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/img/koutu_004.jpg',
        },
        {
          img: 'https://ai-image.s3.cn-north-1.amazonaws.com.cn/img/koutu_005.jpg',
        },
      ],
      // 初始化选中状态
      imgCurrentActive: 0,
      // 初始化选中图
      resultGraphImg:
        'https://ai-image.s3.cn-north-1.amazonaws.com.cn/img/koutu_001.jpg',
      // 成功图
      successImg: false,
      // 失败图
      errImg: true,
      // 正在加载
      loadImg: true,
      // 加载文案
      errText: '处理中....',
      urlAdress: '',
      // 加载时，禁止点击
      prohibitClicking: false,
      // 优化后按钮
      afterOptimization: false,
      // 优化后
      afterOptimization: false,
      // 下载
      down: false,
      isSample: 0,
    };
  },
  computed: {},
  mounted() {
    // 默认点击高亮
    this.selectPicture(this.imgCurrentActive, { img: this.resultGraphImg });
  },
  methods: {
    // 点击选中高亮
    selectPicture(index, { img }) {
      this.resultGraphImg = img;
      this.imgCurrentActive = index;
      this.whiteBackgroundMap(0, this.resultGraphImg);
      // console.log(this.resultGraphImg)
    },
    // 白底图url  请求
    whiteBackgroundMap(status, resultGraphImg) {
      // if (status == 1) {
      //   this.$message.info('暂不支持自定义上传');
      //   return;
      // }
      // 成功图
      this.successImg = false;
      // 失败图
      this.errImg = true;
      // 正在加载
      this.loadImg = true;
      this.errText = '处理中....';

      //优化后
      this.afterOptimization = false;
      // 下载
      this.down = false;

      if (status == 0) {
        // 点击图片获取地址
        var imgUrl = resultGraphImg;
        // 加载时禁止点击切换
        this.prohibitClicking = true;
        this.isSample = 0;
      } else {
        // 点击检测按钮  获取地址
        var imgUrl = this.urlAdress;
        this.imgCurrentActive = -1;
        this.isSample = 1;
      }

      // 请求接口-------------------
      whiteBackground({
        imgUrl: imgUrl,
        isSample: this.isSample,
      }).then(({ data }) => {
        if (data.state === '0x0000') {
          setTimeout(() => {
            this.urlAdress = '';
            // 成功图
            this.successImg = true;
            this.img_url = data.data.img_url;
            this.resultGraphImg = data.data.result_url;
            this.id = data.data.id;

            // 失败图
            this.errImg = false;
            // 正在加载
            this.loadImg = false;
            // 加载完成  可点击切换
            this.prohibitClicking = false;

            //优化后
            this.afterOptimization = true;

            if (status == 1) {
              // 下载
              this.down = true;
            }
            this.$nextTick(function () {
              this.method.dragDropImages();
            });
          }, 1000);
        } else if (data.state === '0x0008') {
          this.$message.error(data.message);
          this.$refs.navheader.logOn();
          // }else if(data.state === "00001"){
        } else {
          setTimeout(() => {
            this.urlAdress = '';
            // 成功图
            this.successImg = false;
            // 失败图
            this.errImg = true;
            this.errText = data.message;
            this.loadImg = false;
            // 加载完成  可点击切换
            this.prohibitClicking = false;

            //优化后
            this.afterOptimization = false;
            // 下载
            this.down = false;
          }, 1000);
        }
      });
    },
    // 图片change操作
    handleChange(file, fileList) {
      // return;
      // 成功图
      this.successImg = false;
      // 失败图
      this.errImg = true;
      // 正在加载
      this.loadImg = true;
      this.errText = '处理中....';
      this.imgCurrentActive = -1;
      // 加载时禁止点击切换
      this.prohibitClicking = true;

      //优化后
      this.afterOptimization = false;
      // 下载
      this.down = false;
    },
    // 图片上传成功后的回调
    getResultImg(response, file, fileList) {
      if (response.state === '0x0000') {
        setTimeout(() => {
          this.img_url = response.data.img_url;
          this.resultGraphImg = response.data.result_url;
          this.id = response.data.id;
          // 成功图
          this.successImg = true;
          // 失败图
          this.errImg = false;
          // 正在加载
          this.loadImg = false;
          // 加载完成  可点击切换
          this.prohibitClicking = false;

          //优化后
          this.afterOptimization = true;
          // 下载
          this.down = true;
          this.isSample = 1;
          this.$nextTick(function () {
            this.method.dragDropImages();
          });
        }, 1000);
      } else if (response.state === '0x0008') {
        this.$message.error(response.message);
        this.$refs.navheader.logOn();
      } else {
        setTimeout(() => {
          // 成功图
          this.successImg = false;
          // 失败图
          this.errImg = true;
          this.errText = response.message;
          this.loadImg = false;
          // 加载完成  可点击切换
          this.prohibitClicking = false;
          //优化后
          this.afterOptimization = false;
          // 下载
          this.down = false;
        }, 1000);
      }
    },
    // 结果导出
    imgDowns() {
      let url = `${apiUri}/img/downloadWhiteBase?id=${this.id}`;
      window.open(url, '_blank');
    },
  },
};
</script>

<style lang="less" scoped>
@transitionTime: 0.2s;
.core-function,
.application-scene,
.similar-main,
.different-advantage {
  width: 1280px;
  margin: auto;
}

.core-function_main,
.application-scene_main,
.different-advantage_main {
  margin-top: 80px;
}
// banner
.header-custom {
  width: 100%;
  margin: auto;
  height: 100%;
  position: absolute;
  display: flex;
  left: 50%;
  transform: translate(-50%);
  align-items: flex-end;
  padding-bottom: 2.8%;
  box-sizing: border-box;
  justify-content: center;
  img {
    position: absolute;
  }
  .header-bg {
    z-index: 1;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  .header-bg_left {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 50%;
    height: 100%;
    background: #fff;
  }
  .header-bg_right {
    content: '';
    position: absolute;
    width: 50%;
    height: 100%;
    right: 0;
    bottom: 0;
    background: #6c38e0;
  }
  @time: 0.7s;
  .banner1 {
    width: 18.2%;
    top: 6%;
    left: 14%;
    margin-right: 1%;
  }
  .banner2 {
    width: 47.9%;
    top: 11%;
    left: 50%;
    transform: translateX(-50%);
    margin-right: 1%;
  }
  .banner3 {
    width: 20.6%;
    left: 50%;
    transform: translateX(-50%);
    top: 41%;
  }
  .banner4 {
    width: 18.2%;
    top: 18%;
    right: 12%;
    margin-right: 1%;
  }
  .banner5 {
    width: 3.33%;
    top: 61%;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
  }
  .banner-range {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(242, 242, 241, 0);
    outline: none;
    appearance: none;
  }
  .banner-range::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 2px;
    height: 100%;
    background: #6c38e0;
    cursor: pointer;
  }
}
.banner-wrap {
  /deep/ .banner-header_cont {
    top: 87%;
    transform: translateY(-115%);
    .banner-title,
    .banner-text {
      color: transparent; //把颜色改成透明
      background: linear-gradient(
        90deg,
        #6c38e0 0%,
        #6c38e0 50%,
        #fff 50%,
        #fff 100%
      );
      -webkit-background-clip: text !important;
      background-clip: text !important;
    }
  }
}
/* 核心 */
.core-function {
  padding-top: 80px;
  padding-bottom: 97px;
  /deep/ .common-two-column {
    column-gap: 24px;
  }
  .common-two-column_img {
    position: relative;
    width: 49.1%;
    margin-right: 0;
    margin-top: -119px;

    .core-list {
      position: absolute;
      width: 100%;
      left: 6.4%;
      bottom: 4.1%;
      display: flex;
      column-gap: 2.55%;
      img {
        width: 24.2%;
        border-radius: 6px;
        transition: all @transitionTime;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
        &:hover {
          transform: scale(1.2);
        }
      }
    }
  }
}
/* 差异优势 */
.different-advantage-wrap {
  background: linear-gradient(140deg, #503ad9 20%, #722ab8 145%);
  .different-advantage {
    padding-top: 80px;
  }

  .different-advantage_main_top {
    margin-top: 90px;
    /deep/ &.common-two-column {
      column-gap: 25px;
    }
    /deep/ .common-two-column_img {
      width: 49%;
      margin-right: 0;
      margin-top: -22px;
    }
    /deep/ .section-article {
      background-image: url(../../assets/img/title/diff-logo.png);
      background-repeat: no-repeat;
      background-size: 90% auto;
      background-position: left bottom;
      padding: 10px 0 65px;
    }
  }
  .different-advantage_main_bottom {
    .diff-bar {
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
    }
    .diff-list {
      position: absolute;
      width: fit-content;
      right: 0;
      top: 0;
      display: flex;
      height: 34.26%;
      align-items: center;
      img {
        position: relative;
        z-index: 3;
        width: auto;
        height: 100%;
        transition: all @transitionTime;
        &:hover {
          transform: scale(1.2);
          z-index: 4;
        }
      }
    }
    /deep/ &.common-two-column {
      column-gap: 24px;
    }
    /deep/ .common-two-column_img {
      width: 48.7%;
      margin-right: 0;
      padding-top: 10px;
      // margin-top: -22px;
    }
    /deep/ .common-two-column_text {
      padding-top: 14%;
      z-index: 2;
    }
  }
  .diff-imgs {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
  .diff-person {
    width: 62.6%;
    position: relative;
    margin-left: -2%;
  }
  .mod_txt {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    float: left;
    overflow: hidden;
    width: 22px;
    height: 30px;
    // text-transform: uppercase;
    font-size: 30px;
    color: #333;
    font-family: Inter;
  }

  .mod_txt:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    word-break: break-all;
    // background: #ffffff;
    font-size: 30px;
    color: #333;
  }
  .diff-top {
    position: relative;
  }
}
/* 应用场景 */
.application-scene {
  padding-top: 80px;
  padding-bottom: 110px;
  /deep/ .common-two-column {
    column-gap: 28px;
  }
  /deep/ .common-two-column_img {
    width: 49.1%;
    margin-right: 22px;
    margin-top: -106px;
  }
  .app-imgs {
    width: 49.1%;
    position: relative;
    margin: auto;
    .app1 {
      width: 100%;
    }
    .app2,
    .app3 {
      position: absolute;
      width: 22%;
      transition: all @transitionTime;
      &:hover {
        transform: scale(120%);
      }
    }
    .app2 {
      left: 17.2%;
      top: 3.1%;
    }
    .app3 {
      right: 19.6%;
      top: 6%;
    }
  }
  .app-title {
    font-size: 24px;
    color: #000;
    border-radius: 157px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      width: 64px;
      background: #ff8645;
      border-radius: 100px;
    }
  }
  .app-list {
    margin-top: 24px;
    row-gap: 24px;
    li {
      display: flex;
      align-items: flex-start;
      color: #5d5f6c;
      font-size: 16px;
      line-height: 22px;
    }
    img {
      width: 22px;
      margin-right: 8px;
    }
  }
}
// 能力展示
.similar-main-wrap {
  padding: 80px 0;
  width: 100%;
  background: #f0f2f5;
  /deep/ .common-text {
    img {
      vertical-align: middle;
      display: inline-block;
      margin: -2px 3px 0;
      width: 24px;
    }
  }
  .operationBackgroundColor {
    column-gap: 16px;
    height: calc(100% - 62px);
  }
  .operationRight {
    height: 100%;
    border-radius: 8px;
  }
  .similarityGraph {
    width: 32.8%;
    background: #d8d8d8;
    border-radius: 8px;
  }
  .operationCenter {
    width: 71.25%;
    height: auto;
    margin-left: 16px;
    img {
      max-width: 100%;
      max-height: 100%;
      width: fit-content;
    }
  }
  .successImgProduct {
    width: 100%;
  }
  .operationYellowRight {
    flex: 1;
    flex-shrink: 0;
    height: 100%;
    margin: 7px 0 0 24px;
  }
  .recognitionResult {
    margin: 0;
    font-size: 18px;
    color: #000108;
    span {
      color: #ff8645;
    }
  }
  .detectionButton {
    background: rgba(108, 56, 224, 0.1);
    color: rgba(108, 56, 224, 1);
  }
  .confidenceLevel {
    margin: 24px 0 0;
    font-size: 14px;
  }
  .similarityGraphMain {
    height: 100%;
    justify-content: space-between;
    row-gap: 0.8%;
    column-gap: 0.8%;
  }
  .similarityGraphImg img {
    border-radius: 8px;
    overflow: hidden;
  }
  .successImg {
    height: 100%;
  }
  .afterOptimization,
  .optimizationButton {
    margin-top: 14px;
    background: rgba(255, 255, 255, 0.5);
    padding: 7px 12px;
    border-radius: 4px;
    width: fit-content;
    height: fit-content;
    line-height: inherit;
    top: 0;
    white-space: nowrap;
  }
}
.similar-main-cont {
  // display: flex;
  width: 100%;
  height: 770px;
  margin: auto;
  background-color: #fff;
  padding: 16px;
  border-radius: 16px;
  box-sizing: border-box;
}
@media screen and(max-width:1280px) {
  .similar-main-wrap {
    .operationBackgroundColor {
      display: block;
    }
    .operationRight {
      margin-top: 20px;
      height: 90vw;
      max-height: 676px;
    }
  }
  .similar-main-cont {
    height: fit-content;
  }
}
@media screen and (max-width: 990px) {
  .different-advantage-wrap {
    .different-advantage_main_bottom {
      .diff-list {
        display: none;
      }
      /deep/.common-two-column_text {
        padding: 20px 0 40px;
      }
    }
  }
}
@media screen and (min-width: 1440px) {
  .header-custom {
    .header-bg {
      width: 1440px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
</style>
